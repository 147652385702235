/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {


  function is_mobile () {
    if ($(window).width() <= 414 && $('html').hasClass('touchevents')) {
      return true;
    }
  }


  function set_sticky_nav() {

    if (is_mobile()) {
      return false;
    }

    setTimeout(function () {
      $('header').stick_in_parent({
        offset_top: 0
      });
    }, 1250);

  }//end set_sticky_nav


  function run_hero_animations() {
    $('section#hero img').addClass('active');

    setTimeout(function () {
      $('section#hero h1').addClass('active');

      setTimeout(function () {
        $('section#hero h1 + p').addClass('active');
      }, 1000);
    }, 500);
  }


  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        if (typeof AOS !== 'undefined') {
          AOS.init();
        }

        //Run preloader
        setTimeout(function () {

          $('div.preloader').fadeTo('slow', 0.00001, function(){
            $('div.preloader').remove();
            run_hero_animations();
          });

        }, 2400);



        //News search click
        $('button.posts-search-btn').click(function(){

          var anchor = $('a.search-anchor');

          var search = $('#ucc-search').val();
          if (!search.length) {
            return false;
          }

          //clean it:
          search = search.replace(/\W/g, ' ');
          var words = search.split(' ');

          //Remove empties
          words = words.filter(function (el) {
            return el != '';
          });

          if (words.length > 1) {
            search = words.join('+');
          }

          var href = anchor.data('base-url');
          anchor.attr('href', href+search);

          window.open(anchor.attr('href'));
          return false;

        });




        //OnScroll
        $(window).on('scroll', function () {

          //Set the active nav
          $('section.page-section').each(function () {

            var offset = $('header.header').height();
            var ost = (parseInt($(this).offset().top) - offset);
            var h = $(this).outerHeight();
            var st = $(window).scrollTop();
            var id = $(this).attr('id');

            if ((st >= ost) && (st <= (ost + h))) {
              $('ul#page-nav li a[href="#' + id + '"]').addClass('active');
            } else {
              $('ul#page-nav li a[href="#' + id + '"]').removeClass('active');
            }

            if( (st + $(window).height()) === $(document).height()) {
              $('ul#page-nav li a').removeClass('active');
              $('ul#page-nav li a[href="#awards--certificates"]').addClass('active');
            } else {
              $('ul#page-nav li a[href="#awards--certificates"]').removeClass('active');
            }

          });


          //Show / hide back to top
          if ($('header').hasClass('is_stuck')) {
            $('a#back-to-top').addClass('active');
          } else {
            $('a#back-to-top').removeClass('active');
          }


          if (is_mobile()) {
            if ($(this).scrollTop() > $('header').outerHeight() ) {
              $('header').addClass('mobile-scrolling');
              $('a#back-to-top').addClass('active');
            } else {
              $('header').removeClass('mobile-scrolling');
              $('a#back-to-top').removeClass('active');
            }
          }


        });//end on Scroll


        $(window).bind('load resize', function () {
          set_sticky_nav();
        });


        //Mobile nav menu click
        $(document).on('click', 'div.hamburger', function () {
          $(this).toggleClass('is-active');
          $('header.header').toggleClass('active');
        });


        //Close the mobile menu on nav item click
        $(document).on('click', 'ul#page-nav li a', function () {
          if ($('div.hamburger').hasClass('is-active')) {
            $('div.hamburger').trigger('click');
          }
        });


        //Page scroll
        $(document).on('click', 'a.page-scroll', function(e){
          var offset = 35,
              t = $(this);
          $("html, body").stop().animate({
            scrollTop: (parseInt($(t.attr("href")).offset().top) - offset)
          }, 1500, "easeInOutExpo");
          e.preventDefault();
        });


        //Attached page-scroll class to any internal links
        $('section.page-section a').each(function(){
          var $this = $(this);
          if (this.hash.length && this.hash !== '') {
            $this.addClass('page-scroll');
          }
        });



        /*imagesLoaded('div.hero-bg', {background: true}, function () {
          $('div.hero-bg').addClass('loaded');
          $('div.hero-bg').next('div.wrapper').addClass('show-wrapper');

          if ($('#booking-preloader').length) {

            setTimeout(function () {
              $('#booking-preloader').fadeTo('500', '0000.1', function(){
                $(this).remove();


              });
            }, 1250);

          }
        });*/


        //Video popups
        // $('.video').magnificPopup({
        //   type: 'iframe',
        //   preloader: true,
        //   removalDelay: 500, //delay removal by X to allow out-animation
        //   callbacks: {
        //     beforeOpen: function () {
        //       // just a hack that adds mfp-anim class to markup
        //       this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');
        //       this.st.mainClass = this.st.el.attr('data-effect');
        //     }
        //   },
        //   closeOnContentClick: true
        // });//end video modal



        //Image popups
        $('.magnific-gallery').each(function () {
          $(this).magnificPopup({
            delegate: 'a',
            type: 'image',
            preloader: true,
            gallery: {
              enabled: true
            },
            removalDelay: 500, //delay removal by X to allow out-animation
            callbacks: {
              beforeOpen: function () {
                // just a hack that adds mfp-anim class to markup
                this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');
                this.st.mainClass = this.st.el.attr('data-effect');
              }
            },
            closeOnContentClick: true,
            midClick: true // allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source.
          });
        });//end image modal


        //Image carousel
        $('.image-carousel').owlCarousel({
          items: 5,
          dots: true,
          nav: false,
          loop: false,
          responsiveClass: true,
          responsive: {
            0: {
              items: 1,
              loop: false
            },
            768: {
              items: 3,
            },
            1024: {
              items: 4,
            },
            1025: {
              items: 5,
            }
          }
        });//end img carousel


        if ($('.awards-carousel').length) {
          $('.awards-carousel').owlCarousel({
            items: 6,
            dots: true,
            nav: false,
            loop: false,
            responsiveClass: true,
            responsive: {
              0: {
                items: 1,
                loop: false
              },
              768: {
                items: 3,
              },
              1024: {
                items: 4,
              },
              1025: {
                items: 5,
              }
            }
          });
        }//end awards carousel


        if ($('.news-carousel').length) {
          $('.news-carousel').owlCarousel({
            items: 4,
            dots: true,
            nav: false,
            loop: false,
            margin: 15,
            responsiveClass: true,
            responsive: {
              0: {
                items: 1,
                loop: false
              },
              768: {
                items: 2,
              },
              1024: {
                items: 3,
              },
              1025: {
                items: 4,
              },
              1450: {
                items: 6
              }
            }
          });
        }//end awards carousel


        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
